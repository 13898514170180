import Cookies from "js-cookie";
import { ReactNode, useEffect, useReducer, useState } from "react";
import { AppDataContext } from "../../contexts/AppDataContext";
import {
  NotificationContext,
  notificationReducer,
} from "../../contexts/NotificationContext";

import { appURL, initialNotificationState } from "../../defaultValues";
import { AppData, SavedItem } from "../../Models/AppData";
import {
  defaultLookupTables,
  LookupTable,
  PromoProduct,
} from "../../Models/LookupTables";
import { ResultType } from "../../Models/Result";
import supabaseAPIManager, {
  APIError,
} from "../../networking/SupabaseAPIManager/SupabaseAPIManager";
import { excludedAccessories } from "../../utils";
import { Inventory, SellerApp } from "../../routes";
import { Product } from "../../Models/Product";

interface AppDataProviderProps {
  children: ReactNode;
}

const AppDataProvider = ({ children }: AppDataProviderProps) => {
  const apiKey = process.env.REACT_APP_SUPABASE_API_KEY;
  const notAvailable = "N/A";
  const [rpat, setRPAT] = useState<string | null>(null);
  const [cart, setCart] = useState<SavedItem[]>([]);
  const [promoService, setPromoService] = useState<PromoProduct | null>(null);

  //   const [productReviews, setProductReviews] = useState< Review[]>([]);
  //   const [reviewRequestStatus, setReviewRequestStatus] = useState<
  //   RequestStatus | APIError
  // >(RequestStatus.Idle);
  // let canLoadMoreReviews = useRef<boolean>(true);

  const lookupTables = defaultLookupTables;
  const [state, dispatch] = useReducer(
    notificationReducer,
    initialNotificationState
  );

  const loadLookupTables = async (): Promise<APIError | boolean> => {
    if (!apiKey) {
      return APIError.default;
    }

    let result = await supabaseAPIManager.getLookupTables(apiKey);

    //  const lookupTables = defaultLookupTables;

    if (result.type === ResultType.Failure) {
      return result.error;
    }

    result.value.forEach((lookupTable) => {
      switch (true) {
        case lookupTable.lookup_table_id === 1:
          lookupTables.category = lookupTable.categories_details;
          break;
        case lookupTable.lookup_table_id === 3:
          lookupTables.accessory = lookupTable.accessories_details;
          break;
        case lookupTable.lookup_table_id === 4:
          lookupTables.color = lookupTable.colors_details;
          break;
        case lookupTable.lookup_table_id === 5:
          lookupTables.size = lookupTable.sizes_details //lookupTable.sizes_details.filter((sizeDetail)=> sizeDetail.count === 1);
          break;
        case lookupTable.lookup_table_id === 6:
          lookupTables.priceRanges = lookupTable.price_ranges_details;
          break;
        default:
          break;
      }
    });

    const subcategoriesLookupTable = result.value.find(
      (lookupTable) => lookupTable.lookup_table_id === 2
    );

    if (subcategoriesLookupTable === undefined) {
      return APIError.default;
    }

    for (let { category_id } of lookupTables.category) {
      const subcategoriesForCategory =
        subcategoriesLookupTable.subcategories_details.filter(
          (subcategory) => subcategory.category_id === category_id && subcategory.count === 1
        );
      lookupTables.subcategory.set(category_id, subcategoriesForCategory);
    }

    //console.dir(lookupTables)

    //setLookupTables(lookupTables)
    return true;
  };

  const canAddItem = (variantId: string, product: Product): boolean => {
    const variantDetails = product.productDetails.find(
      (item) => item.productDetailId === variantId
    );
    if (variantDetails === undefined) {
      return false;
    }

    const foundItem = cart.find((item) => item.variantId === variantId);
    if (foundItem === undefined) {
      return true;
    }

    return foundItem.quantity < variantDetails.stockQuantity;
  };

  /**
   * Updates recover passsword token with a new one
   * @param newRPAT
   */
  const updateRPAT = (newRPAT: string): void => {
    setRPAT(newRPAT);
  };

  const getCategoryTitle = (categoryId: string): string => {
    const item = lookupTables.category.find(
      (item) => item.category_id === categoryId
    );
    return item ? item.category_title : notAvailable;
  };

  const getSubcategoryTitleOfCategory = (
    categoryId: string,
    subcategoryId: string
  ): string => {
    const subcategories = lookupTables.subcategory.get(categoryId);

    if (!subcategories) {
      return notAvailable;
    }

    const item = subcategories.find(
      (item) => item.subcategory_id === subcategoryId
    );
    return item ? item.subcategory_title : notAvailable;
  };

  const sizeTitleForId = (sizeId: string): string => {
    const item = lookupTables.size.find((item) => item.size_id === sizeId);
    return item ? item.size_title : "N/A";
  };

  const colorTitleForId = (colorId: string): string => {
    const item = lookupTables.color.find((item) => item.color_id === colorId);
    return item ? item.color_title : "N/A";
  };

  const accessoryTitleForId = (accessoryId: string): string => {
    const item = lookupTables.accessory.find(
      (item) => item.accessory_id === accessoryId
    );
    return item ? item.accessory_title : "N/A";
  };

  const getLookuptableForType = (
    type: LookupTable,
    categoryId?: string
  ): any[] => {
    switch (type) {
      case LookupTable.category:
        return lookupTables.category;
      case LookupTable.color:
        return lookupTables.color;
      case LookupTable.accessory:
        return lookupTables.accessory.filter(
          (accessory) => !excludedAccessories.includes(accessory.accessory_id)
        );
      case LookupTable.size:
        return lookupTables.size;
      case LookupTable.price:
        return lookupTables.priceRanges;
      case LookupTable.subcategory:
        if (!categoryId) return [];
        let subcategories = lookupTables.subcategory.get(categoryId);
        if (!subcategories) return [];

        return subcategories;

      default:
        return [];
    }
  };

  const getRPAT = (): string | null => {
    return rpat;
  };

  const getPasswordRecoveryData = (): Map<string, string> => {
    let recoveryData = new Map<string, string>();
    const hash = window.location.hash;
    const hashArr = hash
      .substring(1)
      .split("&")
      .map((param) => param.split("="));

    // let type;
    // let accessToken;
    for (const [key, value] of hashArr) {
      if (key === "type" || key === "access_token" || key === "error_code") {
        recoveryData.set(key, value);
      }
    }
    return recoveryData;
  };

  const authProviderDataFromHash = (): Map<string, string> | null => {
    let authData = new Map<string, string>();

    const hash = window.location.hash;
    const hashArr = hash
      .substring(1)
      .split("&")
      .map((param) => param.split("="));

    // let type;
    // let accessToken;
    for (const [key, value] of hashArr) {
      authData.set(key, value);
    }
    return authData;
  };

  const setPreAuthPathname = (currPathname: string) => {
    Cookies.set("preAuthPathname", currPathname);
  };

  const clearSavedAppData = () => {
    Cookies.remove("preAuthPathname");
  };

  const getPreAuthPathname = (): string | undefined => {
    return Cookies.get("preAuthPathname");
  };

  const gotoSellerApp = () => {
    //navigate(`/seller+app/inventory/products`)
    window.location.href = `${appURL + SellerApp.index}/${Inventory.products}`;
  };

  const saveItemToCart = (itemToSave: SavedItem) => {
    let temp: SavedItem[] = [];
    if (cart.length === 0) {
      temp.push(itemToSave);
    } else {
      // add item to cart. If it already exist. Increse its quantity by 1
      const foundIdx = cart.findIndex(
        (savedItem) => savedItem.variantId === itemToSave.variantId
      );
      temp = [...cart];
      if (foundIdx < 0) {
        temp.push(itemToSave);
      } else {
        temp[foundIdx].quantity += 1;
      }
    }
    localStorage.setItem("cart", JSON.stringify(temp));
    setCart(temp);
  };

  const getCart = (): SavedItem[] => {
    let savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  };

  const clearCart = () => {
    localStorage.setItem("cart", JSON.stringify([]));
    setCart([]);
  };

  /**
   * Goes to shopping app. If pathname is not given, it goes to the base which is '/'
   * @param pathname pathname in the shopping app to go to
   */
  const gotoShoppingApp = (pathname?: string, search?: string) => {
    if (pathname) {
      const updatedPathname =
        pathname[0] === "/" ? pathname.substring(1) : pathname;
      window.location.href = `${appURL}/${updatedPathname}`;
    } else {
      window.location.href = appURL;
    }
  };

  const loadPromoProduct = async (): Promise<void> => {
     
    if (!apiKey) {
      throw APIError.default;
    }

    const result = await supabaseAPIManager.getPromoProducts(apiKey);
    if (result.type === ResultType.Failure) {
      throw result.error;
    }

   
    if (result.value.length === 0) {
      return
    }
   
    setPromoService(result.value[0]);
  };

  const updateQuantityForItem = (
    variantId: string,
    newQuantity: number
  ): boolean => {
    const temp = localStorage.getItem("cart");
    if (!temp) {
      return false;
    }

    let savedItems = JSON.parse(temp) as SavedItem[];
    const foundIndex = savedItems.findIndex(
      (item) => item.variantId === variantId
    );
    if (foundIndex < 0) {
      return false;
    }

    try {
      savedItems[foundIndex].quantity = newQuantity;
      localStorage.setItem("cart", JSON.stringify(savedItems));
      const cartItemIdx = cart.findIndex(
        (item) => item.variantId === variantId
      );
      if (cartItemIdx < 0) {
        return false;
      }
      setCart([
        ...cart.slice(0, cartItemIdx),
        { ...cart[cartItemIdx], quantity: newQuantity },
        ...cart.slice(cartItemIdx + 1),
      ]);
      return true;
    } catch (error) {
      return false;
    }

    // localStorage.setItem('cart', JSON.stringify(savedItems))
  };

  const removeBagItem = (variantId: string): SavedItem[] | null => {
    const temp = localStorage.getItem("cart");
    if (!temp) {
      return null;
    }

    let savedItems = JSON.parse(temp) as SavedItem[];
    const remainingItems = savedItems.filter(
      (item) => item.variantId !== variantId
    );
    try {
      localStorage.setItem("cart", JSON.stringify(remainingItems));
      setCart([...remainingItems]);
      return remainingItems;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const appData: AppData = {
    canAddItem,
    cart,
    promoService,
    clearCart,
    clearSavedAppData,
    loadLookupTables,
    loadPromoProduct,

    getCategoryTitle,
    getSubcategoryTitleOfCategory,
    getCart,
    colorTitleForId,
    sizeTitleForId,
    accessoryTitleForId,
    getLookuptableForType,
    updateRPAT,
    getRPAT,
    getPasswordRecoveryData,
    authProviderDataFromHash,
    setPreAuthPathname,
    getPreAuthPathname,
    gotoSellerApp,
    gotoShoppingApp,
    removeBagItem,
    saveItemToCart,
    updateQuantityForItem,
  };

  useEffect(() => {
    //localStorage.clear()
    let savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  return (
    <AppDataContext.Provider value={appData}>
      <NotificationContext.Provider value={{ state, dispatch }}>
        {children}
      </NotificationContext.Provider>
    </AppDataContext.Provider>
  );
};

export default AppDataProvider;
